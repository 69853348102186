<template>
  <div :class="toggleClass.formGroup">
    <label :for="id" :class="toggleClass.required">{{ label }}</label>

    <div :class="toggleClass.toggler" @click="handleClick" :id="id">
      <div :class="toggleClass.bar"></div>
      <svg
        :height="circle.size"
        :width="circle.size"
        :class="toggleClass.circle"
      >
        <circle
          :cx="circle.coordinate"
          :cy="circle.coordinate"
          :r="circle.radius"
          :stroke-width="circle.strokeWidth"
        />
      </svg>
    </div>

    <input
      :id="id"
      type="radio"
      :name="name"
      :class="toggleClass.radio"
      v-model="innerValue"
    />
  </div>
</template>

<script>
import { inputMixin } from '@/mixins'
import { CSN_INPUT_CLASS, EMPTY_STRING, TOGGLE_FIELD, Digit } from '@/constants'

const FORM_GROUP = 'form-group'
const FORM_CONTROL = 'form-control'
const REQUIRED = 'required'
const CSN_TOGGLE = 'csn-toggle '
const CSN_TOGGLE_CHECKED = 'csn-toggle-checked'
const CSN_TOGGLE_BAR = 'csn-toggle-bar'
const CSN_TOGGLE_CIRLE = 'csn-toggle-circle'
const CSN_TOGGLE_RADIO = 'csn-toggle-radio'

const circle = {
  size: Digit.THIRTY,
  coordinate: Digit.FIFTEEN,
  radius: Digit.FOURTEEN__FIVE,
  strokeWidth: Digit.ONE,
}

export default {
  name: TOGGLE_FIELD,
  inheritAttrs: false,
  mixins: [inputMixin],
  data: () => ({}),
  props: {
    label: String,
    value: Boolean,
  },
  computed: {
    isChecked() {
      return !!this.value
    },
    toggleClass() {
      return {
        formGroup: [CSN_INPUT_CLASS, FORM_GROUP],
        required: REQUIRED,
        toggler: [
          FORM_CONTROL,
          CSN_TOGGLE,
          this.isChecked ? CSN_TOGGLE_CHECKED : EMPTY_STRING,
        ],
        bar: CSN_TOGGLE_BAR,
        circle: CSN_TOGGLE_CIRLE,
        radio: CSN_TOGGLE_RADIO,
      }
    },
    circle: () => circle,
  },
  methods: {
    handleClick() {
      this.handleFocus()
      this.handleInput(!this.innerValue)
    },
  },
}
</script>
